<template>
  <div class="pt-12 mt-6">
    <v-container>
      <v-row>
        <v-col cols="12" class="text-center">
          <h1 class="display-1 mb-4 font-weight-bold">
            Gizlilik Politikası
          </h1>
          <p class="mx-auto" style="width: 75%;">
            UTEx kapsamında sunulan tüm hizmetler burada sunulan gizlilik ve
            bilgi güvenliği ilkeleri esas alınarak sunulmaktadır. Kişisel
            bilgilerinizin gizliliği ve güvenliği bizim için önem taşıyor.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="mb-4">
          <p>
            UTE Akademi A.Ş. ("Biz", "Bizim" veya "UTE Akademi") olarak,
            kullanıcılarımızın hizmetlerimizden güvenli ve eksiksiz şekilde
            faydalanmalarını sağlamak amacıyla sitemizi kullanan üyelerimizin
            gizliliğini korumak için çalışıyoruz. Bu doğrultuda, işbu UTE
            Akademi Gizlilik Politikası (“Politika”), üyelerimizin kişisel
            verilerinin 6698 sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”)
            ile tamamen uyumlu bir şekilde işlenmesi ve kullanıcılarımızı bu
            bağlamda bilgilendirmek amacıyla hazırlanmıştır. uteakademi.com
            çerez politikası işbu politikanın ayrılmaz parçasıdır.
          </p>
          <p>
            İşbu politikanın amacı, UTE Akademi tarafından işletilmekte olan
            www.uteakademi.com internet sitesi ile mobil uygulamanın (hepsi
            birlikte “Platform” olarak anılacaktır) işletilmesi sırasında
            Platform üyeleri/ziyaretçileri/kullanıcıları (hepsi birlikte “Veri
            Sahibi” olarak anılacaktır) tarafından UTE Akademi ile paylaşılan
            veya UTE Akademinin, Veri Sahibi’nin Platform’u kullanımı sırasında
            ürettiği kişisel verilerin kullanımına ilişkin koşul ve şartları
            tespit etmektir.
          </p>
          <h3>Hangi Veriler İşlenmektedir?</h3>
          <p>
            Aşağıda UTE Akademi tarafından işlenen ve Kanun uyarınca kişisel
            veri sayılan verilerin hangileri olduğu sıralanmıştır. Aksi açıkça
            belirtilmedikçe, işbu Politika kapsamında arz edilen hüküm ve
            koşullar kapsamında “kişisel veri” ifadesi aşağıda yer alan
            bilgileri kapsayacaktır.
          </p>
          <ul class="mb-4">
            <li>Kimlik Bilgisi</li>
            <li>İletişim Bilgisi</li>
            <li>Kullanıcı Bilgisi</li>
            <li>Kullanıcı İşlem Bilgisi</li>
            <li>İşlem Güvenliği Bilgisi</li>
            <li>Finansal Bilgi</li>
            <li>Pazarlama Bilgisi</li>
            <li>Talep/Şikayet Yönetimi Bilgisi</li>
          </ul>
          <p>
            Kişisel Verilerin Korunması Kanunu’nun 3. ve 7. maddeleri
            dairesince, geri döndürülemeyecek şekilde anonim hale getirilen
            veriler, anılan kanun hükümleri uyarınca kişisel veri olarak kabul
            edilmeyecek ve bu verilere ilişkin işleme faaliyetleri işbu Politika
            hükümleri ile bağlı olmaksızın gerçekleştirecektir.
          </p>
          <h3>Kişisel Veri İşleme Amaçları</h3>
          <p>
            UTE Akademi, Veri Sahibi tarafından sağlanan kişisel verileri,
            üyelik kaydı ve hesabının oluşturulması ve buna ilişkin kayıtların
            tutulması, Veri Sahibi’nin Platform üzerinden sağlanan hizmetlerden
            faydalandırılması sistem hatalarının tespit edilerek performans
            takibinin yapılması ve Platform’un işleyişinin iyileştirilmesi,
            bakım ve destek hizmetleri ile yedekleme hizmetlerinin sunulması
            amaçları dahil olmak üzere UTE Akademi tarafından sunulan ürün ve
            hizmetlerden ilgili kişileri faydalandırmak için gerekli
            çalışmaların iş birimleri tarafından yapılması ve ilgili iş
            süreçlerinin yürütülmesi ile bu ürün ve hizmetlerin ilgili kişilerin
            beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre
            özelleştirilerek ilgili kişilere önerilmesi ve tanıtılması için
            gerekli olan aktivitelerin planlanması ve icrası, UTE Akademi
            tarafından yürütülen ticari faaliyetlerin gerçekleştirilmesi için
            ilgili iş birimleri tarafından gerekli çalışmaların yapılması ve
            buna bağlı iş süreçlerinin yürütülmesi, UTE Akademi ve iş ilişkisi
            içerisinde bulunduğu kişilerin hukuki, teknik ve ticari-iş
            güvenliğinin temini ile UTE Akademinin ticari ve/veya iş
            stratejilerinin planlanması ve icrası amaçlarıyla işlenebilecektir.
          </p>
          <h3>
            Veri Sahiplerinin Açık Rızası Doğrultusunda İşlenecek Kişisel
            Veriler ve İşleme Amaçları
          </h3>
          <p>
            Veri Sahibi’nin açık rızası kapsamında, UTE Akademi, Veri
            Sahipleri’nin Platform üzerindeki hareketlerini takip ederek
            kullanıcı deneyiminin artırılması, istatistik oluşturulması,
            profilleme yapılması, doğrudan pazarlama ve yeniden pazarlama, Veri
            Sahibi’ne özel promosyon önerilerinin oluşturulması ve Veri
            Sahibi’ne iletilmesi ve bu kapsamda elde edilen verilerin her türlü
            reklam ve materyal içeriğinde kullanılması amacıyla veri
            işleyebilecek ve aşağıda anılan taraflarla bu verileri
            paylaşabilecektir.
          </p>
          <h3>Kişisel Verilerin Aktarımı</h3>
          <p>
            UTE Akademi, Veri Sahibi’ne ait kişisel verileri ve bu kişisel
            verileri kullanılarak elde ettiği yeni verileri, işbu Politika ile
            belirlenen amaçların gerçekleştirilebilmesi için UTE Akademinin
            hizmetlerinden faydalandığı üçüncü kişilere, söz konusu hizmetlerin
            temini amacıyla sınırlı olmak üzere aktarılabilecektir. UTE Akadmei,
            Veri Sahibi deneyiminin geliştirilmesi (iyileştirme ve
            kişiselleştirme dâhil), Veri Sahibi’nin güvenliğini sağlamak, hileli
            ya da izinsiz kullanımları tespit etmek, operasyonel değerlendirme
            araştırılması, Platform hizmetlerine ilişkin hataların giderilmesi
            ve işbu Gizlilik Politikası’nda yer alan amaçlardan herhangi
            birisini gerçekleştirebilmek için SMS gönderimi yapanlar da dahil
            olmak üzere dış kaynak hizmet sağlayıcıları, barındırma hizmet
            sağlayıcıları (hosting servisleri), hukuk büroları, araştırma
            şirketleri, çağrı merkezleri gibi üçüncü kişiler ile
            paylaşabilecektir.
          </p>
          <p>
            Kişisel veriler, Kanun’un 8. ve 9. maddelerinde belirtilen kişisel
            veri işleme şartları ve amaçları çerçevesinde UTE Akademi Şirket
            yetkilileri, hissedarları, iş ortaklarımız, tedarikçilerimiz,
            kanunen yetkili kamu kurum ve kuruluşları ile kanunen yetkili özel
            kurumlar ile paylaşılabilecek, bu amaçlarla sınırlı olarak Kanun
            m.9’da işaret edilen usul esaslar ile Kişisel Verileri Koruma Kurulu
            kararları çerçevesinde yurt dışına aktarılabilecektir.
          </p>
          <h3>Kişisel Verilerin Toplanma Yöntemi ve Hukuki Sebebi</h3>
          <p>
            Kişisel veriler, Platform üzerinden ve elektronik ortamda
            toplanmaktadır. Yukarıda belirtilen hukuki sebeplerle toplanan
            kişisel veriler 6698 sayılı Kanun’un 5. ve 6. maddelerinde ve bu
            Gizlilik Politikası’nda belirtilen amaçlarla işlenebilmekte ve
            aktarılabilmektedir.
          </p>
          <h3>Kişisel Veri Sahibinin Hakları</h3>
          <p>Kanun’un 11. maddesi uyarınca veri sahipleri,</p>
          <ul class="mb-4">
            <li>
              Kendileri ile ilgili kişisel veri işlenip işlenmediğini öğrenme,
              kişisel verileri işlenmişse buna ilişkin bilgi talep etme,
            </li>
            <li>
              Kişisel verilerin işlenme amacını ve bunların amacına uygun
              kullanılıp kullanılmadığını öğrenme, yurt içinde veya yurt dışında
              kişisel verilerin aktarıldığı üçüncü kişileri bilme,
            </li>
            <li>
              Kişisel verilerin eksik veya yanlış işlenmiş olması halinde
              bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin
              kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini
              isteme,
            </li>
            <li>
              Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş
              olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan
              kalkması halinde kişisel verilerin silinmesini veya yok edilmesini
              isteme ve bu kapsamda yapılan işlemin kişisel verilerin
              aktarıldığı üçüncü kişilere bildirilmesini isteme,
            </li>
            <li>
              İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz
              edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya
              çıkmasına itiraz etme ve kişisel verilerin kanuna aykırı olarak
              işlenmesi sebebiyle zarara uğraması halinde zararın giderilmesini
              talep etme haklarına sahiptir.
            </li>
          </ul>
          <p>
            Söz konusu hakların kullanımına ilişkin talepler, kişisel veri
            sahipleri tarafından www.uteakademi.com adresinde yer alan 6698
            sayılı Kanun Kapsamında UTE Akadmei tarafından hazırlanan Kişisel
            Verilerin İşlenmesi ve Korunmasına ilişkin Politika’da belirtilen
            yöntemlerle iletilebilecektir. UTE Akademi, söz konusu talepleri
            otuz gün içerisinde sonuçlandıracaktır. UTE Akademinin taleplere
            ilişkin olarak Kişisel Verileri Koruma Kurulu tarafından belirlenen
            (varsa) ücret tarifesi üzerinden ücret talep etme hakkı saklıdır.
          </p>
          <h3>Çerez Politikası</h3>
          <p>
            UTE Akademi olarak, kullanıcılarımızın hizmetlerimizden güvenli ve
            eksiksiz şekilde faydalanmalarını sağlamak amacıyla sitemizi
            kullanan kişilerin gizliliğini korumak için çalışıyoruz.
          </p>
          <p>
            Çoğu web sitesinde olduğu gibi, www.uteakademi.com (“Site”) ile
            mobil uygulamanın (hepsi birlikte “Platform” olarak anılacaktır)
            ziyaretçilere kişisel içerik ve reklamlar göstermek, site içinde
            analitik faaliyetler gerçekleştirmek ve ziyaretçi kullanım
            alışkanlıklarını takip etmek amacıyla Çerezler kullanılmaktadır.
          </p>
          <p>
            İşbu Çerez Politakası www.uteakademi.com Gizlilik Politikası’nın
            ayrılmaz bir parçasıdır.
          </p>
          <p>
            UTE Akademi, bu Çerez Politikası’nı (“Politika”) Site’de hangi
            Çerezlerin kullanıldığını ve kullanıcıların bu konudaki tercihlerini
            nasıl yönetebileceğini açıklamak amacıyla hazırlamıştır.
          </p>
          <h3>Çerez (“Cookie”) Nedir?</h3>
          <p>
            Çerezler, ziyaret ettiğiniz internet siteleri tarafından tarayıcılar
            aracılığıyla cihazınıza veya ağ sunucusuna depolanan küçük metin
            dosyalarıdır. Çerezler, ziyaret ettiğiniz web sitesiyle ilişkili
            sunucular tarafından oluşturulurlar. Böylelikle ziyaretçi aynı
            siteyi ziyaret ettiğinde sunucu bunu anlayabilir.
          </p>
          <p>
            Çerezler, ziyaretçilere ilişkin isim, cinsiyet veya adres gibi
            kişisel verileri içermezler. Çerezler konusunda daha detaylı bilgi
            için
            <a href="http://www.aboutcookies.org" target="_blank"
              >www.aboutcookies.org</a
            >
            ve
            <a href="www.allaboutcookies.org" target="_blank"
              >www.allaboutcookies.org</a
            >
            ziyaret edebilirisiniz.
          </p>
          <h3>Hangi Çerezler Kullanılmaktadır?</h3>
          <p>
            Çerezler, sahipleri, kullanım ömürleri ve kullanım amaçları açısında
            kategorize edilebilir:
          </p>
          <ul class="mb-4">
            <li>
              Çerezi yerleştiren tarafa göre, Platform çerezleri ve üçüncü taraf
              Çerezler kullanılmaktadır. Platform çerezleri, UTE Akademi
              tarafından oluşturulurken, üçüncü taraf çerezlerini UTE Akademi
              ile iş birlikteliği olan farklı firmalar yönetmektedir.
            </li>
            <li>
              Aktif olduğu süreye göre, oturum çerezleri ve kalıcı çerezler
              kullanılmaktadır. Oturum çerezleri ziyaretçinin Platform’u terk
              etmesiyle birlikte silinirken, kalıcı çerezler ise kullanım
              alanına bağlı olarak çeşitli sürelerle ziyaretçilerin cihazlarında
              kalabilmektedir.
            </li>
            <li>
              Kullanım amaçlarına göre, Platform’da teknik çerezler, doğrulama
              çerezleri, hedefleme/reklam çerezleri, kişiselleştirme çerezleri
              ve analitik çerezler kullanılmaktadır.
            </li>
          </ul>
          <h3>Neden Çerezler Kullanılmaktadır?</h3>
          <p>
            Platform’da, Çerezler aşağıdaki amaçlar kapsamında kullanılmaktadır:
          </p>
          <ul class="mb-4">
            <li>
              Platform’un çalışması için gerekli temel fonksiyonları
              gerçekleştirmek. Örneğin, UTE Akademi üyelerinin, ürün ürünlerin
              ziyaretleri süresince kaybolmaması. Oturum açan üyelerin
              Platform’da farklı sayfaları ziyaret ederken tekrar şifre şifre
              girmelerine gerek kalmaması
            </li>
            <li>
              Platform’u analiz etmek ve Platform’un performansını arttırmak.
              Örneğin, Platform’un üzerinde çalıştığı farklı sunucuların
              entegrasyonu, Platform’u ziyaret edenlerin sayısının tespit
              edilmesi ve buna göre performans ayarlarının yapılması ya da
              ziyaretçilerin aradıklarını bulmalarının kolaylaştırılması
            </li>
            <li>
              Platform’un işlevselliğini arttırmak ve kullanım kolaylığı
              sağlamak. Örneğin, Platform üzerinden üçüncü taraf sosyal medya
              mecralarına paylaşımda bulunmak, Platform’u ziyaret eden
              ziyaretçinin daha sonraki ziyaretinde kullanıcı adı bilgisinin ya
              da arama sorgularının hatırlanması
            </li>
            <li>
              Kişiselleştirme, hedefleme ve reklamcılık faaliyeti
              gerçekleştirmek. Örneğin, ziyaretçilerin görüntüledikleri sayfa ve
              ürünler üzerinden ziyaretçilerin ilgi alanlarıyla bağlantılı
              reklam gösterilmesi.
            </li>
          </ul>
          <h3>Çerez Tercihlerinizi Nasıl Yönetebilirsiniz?</h3>
          <p>
            UTE Akademi, kullanıcıların kendilerine ait kişisel veriler
            üzerindeki tercihlerini kullanabilmelerini son derece
            önemsemektedir. Bununla birlikte, Site’nin çalışması için zorunlu
            olan bazı Çerezler konusunda tercih yönetimi mümkün olmamaktadır.
            Ayrıca bazı Çerezlerin kapatılması halinde Site’nin çeşitli
            fonksiyonlarının çalışmayabileceğini hatırlatma isteriz.
          </p>
          <p>
            Platform’da kullanılan Çerezlere dair tercihlerin ne şekilde
            yönetebileceğine dair bilgiler aşağıdaki gibidir:
          </p>
          <ul class="mb-4">
            <li>
              Ziyaretçiler, Platform’u görüntüledikleri tarayıcı ayarlarını
              değiştirerek çerezlere ilişkin tercihlerini kişiselleştirme
              imkanına sahiptir. Eğer kullanılmakta olan tarayıcı bu imkânı
              sunmaktaysa, tarayıcı ayarları üzerinden Çerezlere ilişkin
              tercihleri değiştirmek mümkündür.
            </li>
            <li>
              Böylelikle, tarayıcının sunmuş olduğu imkanlara göre farklılık
              gösterebilmekle birlikte, veri sahiplerinin çerezlerin
              kullanılmasını engelleme, çerez kullanılmadan önce uyarı almayı
              tercih etme veya sadece bazı Çerezleri devre dışı bırakma ya da
              silme imkanları bulunmaktadır. Bu konudaki tercihler kullanılan
              tarayıcıya göre değişiklik göstermekle birlikte genel açıklamaya
              <a href="https://www.aboutcookies.org" target="_blank"
                >https://www.aboutcookies.org</a
              >
              adresinden ulaşmak mümkündür. Çerezlere ilişkin tercihlerin,
              ziyaretçinin Platform’a erişim sağladığı her bir cihaz özelinde
              ayrı ayrı yapılması gerekebilecektir.
            </li>
            <li>
              <a href="https://tools.google.com/dlpage/gaoptout" target="_blank"
                >Google Analytics tarafından yönetilen Çerezleri kapatmak için
                tıklayınız.</a
              >
            </li>
            <li>
              <a
                href="https://adssettings.google.com/authenticated"
                target="_blank"
                >Google tarafından sağlanan kişiselleştirilmiş reklam deneyimini
                yönetmek için tıklayınız.</a
              >
            </li>
            <li>
              Birçok firmanın reklam faaliyetleri için kullandığı çerezler
              bakımından tercihler
              <a
                href="https://www.youronlinechoices.com/tr/reklam-tercihleriniz"
                target="_blank"
                >Your Online Choices</a
              >
              üzerinden yönetilebilir.
            </li>
            <li>
              Mobil cihazlar üzerinden Çerezleri yönetmek için mobil cihaza ait
              ayarlar menüsü kullanılabilir.
            </li>
          </ul>
          <h3>Hangi Haklara Sahipsiniz?</h3>
          <p>
            6698 Sayılı Kişisel Verilerin Korunması Kanunu’nun 11. maddesi
            uyarınca ziyaretçiler, UTE Akademiye başvurarak, kendileriyle
            ilgili,
          </p>
          <ul class="mb-4">
            <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
            <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
            <li>
              Kişisel verilerin işlenme amacını ve bunların amacına uygun
              kullanılıp kullanılmadığını öğrenme,
            </li>
            <li>
              Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü
              kişileri bilme,
            </li>
            <li>
              Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde
              bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin
              kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini
              isteme,
            </li>
            <li>
              Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş
              olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan
              kalkması hâlinde kişisel verilerin silinmesini veya yok edilmesini
              isteme ve bu kapsamda yapılan işlemin kişisel verilerin
              aktarıldığı üçüncü kişilere bildirilmesini isteme,
            </li>
            <li>
              İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz
              edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya
              çıkmasına itiraz etme,
            </li>
            <li>
              Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara
              uğraması hâlinde zararın giderilmesini talep etme haklarına
              sahiptir.
            </li>
          </ul>
          <p>
            Söz konusu haklar, kişisel veri sahipleri tarafından 6698 sayılı
            Kanun Kapsamında UTE Akademi tarafından hazırlanan Kişisel İşlenmesi
            ve Korunmasına ilişkin Politika’da belirtilen yöntemlerle
            yöntemlerle iletildiğinde her hâlükârda 30 (otuz) gün içerisinde
            değerlendirilerek sonuçlandırılacaktır. Taleplere ilişkin olarak
            herhangi bir ücret talep edilmemesi esas olmakla birlikte, UTE
            Akademi, Kişisel Verileri Koruma Kurulu tarafından belirlenen ücret
            tarifesi üzerinden ücret talep etme hakkını saklı tutar.
          </p>
          <h3>Rıza ve Gizlilik Politikası’ndaki Değişiklikler</h3>
          <p>
            UTE Akademi, Gizlilik Politikası (“Politika”) ile kullanıcılarına
            Çerez kullanımının kapsamı ve amaçları hakkında detaylı açıklama
            sunmayı ve Çerez tercihleri konusunda kullanıcılarını
            bilgilendirmeyi hedeflemiştir. Bu bakımdan, Platform’da yer alan
            Çerez bilgilendirme uyarısının kapatılması ve Site’nin kullanılmaya
            devam edilmesi halinde Çerez kullanımına rıza verildiği kabul
            edilmektedir. Kullanıcıların Çerez tercihlerini değiştirme imkânı
            her zaman saklıdır.
          </p>
          <p>
            UTE Akademi, Politika hükümlerini dilediği zaman değiştirebilir.
            Güncel Politika Platform’da yayınlandığı tarihte yürürlük kazanır.
          </p>
          <p class="small text-right">
            Bu belge, en son 14 Ağustos 2020 tarihinde güncellenmiştir.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "Gizlilik Politikası"
  }
};
</script>
